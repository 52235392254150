import { FontFamily } from "../types/FontFamily";

export const fontList: FontFamily[] = [
  // custom Fonts
  "Atkinson-Hyperlegible",
  "Escolar",
  "Massallera",
  "Memima",
  "OpenDyslexic",
  "Pipomayu",

  // 50 Most google fonts use
  "Abel",
  "Abril Fatface",
  "Anton",
  "Archivo",
  "Arimo",
  "Arvo",
  "Asap",
  "Asap Condensed",
  "Assistant",
  "Barlow",
  "Barlow Condensed",
  "Bebas Neue",
  "Bitter",
  "Cabin",
  "Cairo",
  "Catamaran",
  "Caveat",
  "Comfortaa",
  "Cormorant Garamond",
  "Crimson Text",
  "DM Sans",
  "Dancing Script",
  "Dosis",
  "EB Garamond",
  "Exo 2",
  "Fira Sans",
  "Fira Sans Condensed",
  "Fjalla One",
  "Heebo",
  "Hind",
  "Hind Madurai",
  "Hind Siliguri",
  "IBM Plex Mono",
  "IBM Plex Sans",
  "Inconsolata",
  "Inter",
  "Josefin Sans",
  "Jost",
  "Kanit",
  "Karla",
  "Lato",
  "Libre Baskerville",
  "Libre Franklin",
  "Lobster",
  "Lora",
  "M PLUS Rounded 1c",
  "Manrope",
  "Maven Pro",
  "Merriweather",
  "Merriweather Sans",
  "Montserrat",
  "Mukta",
  "Mulish",
  "Nanum Gothic",
  "Noto Color Emoji",
  "Noto Sans",
  "Noto Sans Arabic",
  "Noto Sans JP",
  "Noto Sans KR",
  "Noto Sans SC",
  "Noto Sans TC",
  "Noto Serif",
  "Noto Serif JP",
  "Nunito",
  "Nunito Sans",
  "Open Sans",
  "Oswald",
  "Overpass",
  "Oxygen",
  "PT Sans",
  "PT Sans Narrow",
  "PT Serif",
  "Pacifico",
  "Playfair Display",
  "Poppins",
  "Prompt",
  "Public Sans",
  "Quicksand",
  "Rajdhani",
  "Raleway",
  "Red Hat Display",
  "Righteous",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Rubik",
  "Shadows Into Light",
  "Signika Negative",
  "Slabo 27px",
  "Source Code Pro",
  "Space Grotesk",
  "Tajawal",
  "Teko",
  "Titillium Web",
  "Ubuntu",
  "Varela Round",
  "Work Sans",
  "Yanone Kaffeesatz",
  "Zilla Slab",
];
